import { render, staticRenderFns } from "./Nosology.vue?vue&type=template&id=5178b3e5&scoped=true&"
import script from "./Nosology.vue?vue&type=script&lang=js&"
export * from "./Nosology.vue?vue&type=script&lang=js&"
import style0 from "./Nosology.vue?vue&type=style&index=0&id=5178b3e5&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5178b3e5",
  null
  
)

export default component.exports