<template>
  <div>
    <h1>Выбор нозологии</h1>
    <div class="nosologybox">
      <div v-for="(n, index) in nosologies" :key="index" class="noscheck">
        <CheckBox 
          :key="index"
          :title="n.title" 
          :checked="index == curNosology" 
          v-on:click.native="selectNosology(n.id)" />
      </div>
    </div>
    <router-link
      to="/info"
      class="next_btn">
      Продолжить
    </router-link>
  </div>
</template>

<script>
import CheckBox from '@/components/CheckBox.vue'
import { eventBus } from '@/main'
import dataStore from '@/views/data'

export default {
  components:{
    CheckBox
  },
  props:{
    curNosology:Number,
  },
  data:function(){

      let data = [], indic = {}, indSet = [];

      indSet = dataStore.nosology;
      for(let cnt=0, len=indSet.length ; cnt<len ; cnt++) {
        indic = {};
        indic.id = cnt;
        indic.title = indSet[cnt];
        data.push(indic);
      }  
      return {nosologies:data};

//    return{
      // nosologies:[
      //   {id:0,title:'Меланома'},
      //   {id:1,title:'ГЦК'},
      //   {id:2,title:'ПКР'},
      //   {id:3,title:'КРР'},
      //   {id:4,title:'Плоскоклеточный НМРЛ Л1'},
      //   {id:5,title:'Неплоскоклеточный НМРЛ Л1'},
      // ]
//    }
  },
  methods:{
    selectNosology:function(id){

      const methSet = dataStore.pathMethods[id];
      let defaultMeth = 0;

      while(methSet[defaultMeth]==0) defaultMeth++;
      defaultMeth++;
      dataStore.currIndic = id;
      if(id>1) eventBus.$emit('flag-change', false); // Программа шеринга только для меланомы и ГЦК
      dataStore.currMethod = defaultMeth;
      eventBus.$emit('nosology-change', id);
    }
  }
}
</script>
<style scoped>
h1{
  margin: 10px 0 20px 0;
}
.nosologybox{
  padding: 40px;
  background: linear-gradient(90deg, rgb(255,255,255,0.4), transparent);
  margin: 25px 0 0 3px;
}
.noscheck{
  padding: 10px 0;
}

.next_btn{
  margin: auto;
  margin-top: 15px;
  display: block;
  width: 200px;
  background-color: #03417A;
  text-decoration: none;
  color: #fff;
  font-family: 'Roboto';
  text-align: center;
  padding: 12px;
}
</style>